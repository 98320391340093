.inputRow {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 25px;
}
.inputLabelContainer {
  display: flex;
}
.inputLabel {
  font-size: 13px;
  font-weight: 600;
  padding-right: 30px;
  text-align: end;
}

.text {
  font-size: 13px;
  color: #222;
  padding: 4px 15px;
  font-weight: 450;
}