.ContentsWrapper {
  background-color: #fff;
  position: absolute;
  top: 64px;
  height: calc(100% - 65px);
  width: 100vw;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.NavbarWrapper {
  background-color: #07396d;
  height: 100%;
  width: 200px;
  overflow-x: hidden;
}

.ContentsArea {
  position: relative;
  width: calc(100% - 200px);
  height: calc(100%);
  padding: 10px;
}

.ContentsAreaFull {
  position: relative;
  width: 100%;
  height: calc(100%);
  padding: 10px;
}

.ContentsView {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
}
.popupSmall {
  width: 40%;
  margin: auto;
  background: white;
}
.popupBig {
  width: 60%;
  margin: auto;
  background: white;
}
