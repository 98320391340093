.columnCell{
    font-weight:700 !important;
}
.btn{
    border:1px solid rgba(0,0,1,.1) !important;
}
.image{
    min-height:50px;
    max-height: 50px;
    background-size:contain !important;
    margin:5px;
    justify-content: center;
}