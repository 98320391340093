.mainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ececec;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.outerBoxFrame {
  background-color: #fff;
  border: 1px solid #3f8dce;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  max-width: 800px;
  min-width: 350px;
  /* width: 70%; */
}
.outerBoxFrameWithoutAd {
  background-color: #fff;
  border: 1px solid #3f8dce;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  max-width: 800px;
  min-width: 400px;
}
.detailDiv {
  background-color: #271401;
  border-radius: 6px 0 0 6px !important;
}
.formBoxFrame {
  padding: 35px;
}

.loginForm {
  margin-top: 35px;
}

.titleTextYes {
  margin: 20px;
  color: #404040;
  text-align: center;
}

.textCenter {
  margin-top: 20px;
  text-align: center;
}

.forgotPasswordWrapper {
  font-size: 14px;
  margin-top: 14px;
  text-align: end;
  color: #434a53;
  text-decoration: transparent;
}

.forgotPassword {
  text-decoration-color: transparent;
  color: #434a53;
}

.socialSignInWrapper {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.socialMeadiaLogin {
  margin: 8px;
}

.signupHintWrapper {
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
}

/* Review below styles */

.subHeadDiv {
  display: flex;
  justify-content: flex-start;
}

.primarySubHead {
  color: #2565ad;
  margin-bottom: 10px;
}

.test {
  padding: 10px;
}

.category {
  font-size: 15px;
  color: white;
  line-height: 8;
}

.iconSec {
  position: absolute;
  top: 10px;
  right: 10px;
}

.icon {
  font-size: 50px !important;
  color: #666;
  opacity: 0.5;
}

.center {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
}

.flex60 {
  background-color: #fff;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome {
  margin-top: 40px;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 24px !important;
}

.paddingp {
  padding: 20px;
}

.flex40 {
  flex-direction: column;
  flex: 50%;
}

.errorMsg {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 13px;
}

.btnKnwon {
  margin-left: 20px;
}

.signup {
  margin-bottom: 10px;
  color: #fc9a02;
  justify-content: center;
  display: flex;
}

.loginButton {
  text-align: center;
  width: 60%;
  margin: 20px 20%;
}

.loginLoader {
  position: relative;
  top: 20px;
  left: 11px;
}

.label {
  font-size: 11px;
  font-weight: 500;
  color: #666;
  text-align: center;
}

.labelDiv {
  justify-content: center;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.signupQuote {
  font-size: 13px;
  font-weight: 600;
  margin-left: 20px;
  color: #5f6368;
}

.contentLayout {
  display: flex;
}

.fieldHead {
  width: 40%;
}

.fieldInput {
  width: 50%;
}

.fieldTitle {
  vertical-align: -webkit-baseline-middle;
  font-size: 14px;
  font-weight: 600;
  text-align: end;
}

.fieldTitleSmall {
  vertical-align: -webkit-baseline-middle;
  font-size: 13px;
  width: 150px;
  font-weight: 500;
}

.pageHead {
  font-size: 20px;
  font-weight: 500;
}

.BillHeader {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.formPaper {
  margin: 0 auto;
  width: 900px;
}

/* review below styles */

.box {
  width: 660px;
  height: 500px;
  display: flex;
  border: 0.5px solid #fc9a02;
}

.textMarg {
  margin-top: 15px;
  justify-content: center;
  display: flex;
}

.forgot {
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 14px;
  justify-content: space-between;
}

.signin {
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
  color: #ff8000;
}

.button {
  width: 150px;
}

@media screen and (max-width: 480px) {
  .flex40 {
    display: none;
  }
  .box {
    width: 100%;
  }
}
/* PLANS PAGE STYLES */

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(237 247 246);
  flex-direction: column;
}

.plansOuterBoxFrameWithoutAd {
  background-color: #fff;
  border: 1px solid #f7f7f7;
  display: flex;
  justify-content: center;
  max-width: 800px;
  min-width: 400px;
  height: 400px;
  box-shadow: 0px 1px 1px 0px #a7a7a7;
}
.plansFormBoxFrame {
  padding: 15px;
}
.plansTitleContainer {
  text-align: center;
}
.plansTitleContainer h3 {
  letter-spacing: 4px;
}
.standardPlanContainer {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.premiumPlanContainer {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.plansButtonContainer {
  background-color: #3f8dce;
  text-align: center;
  color: #fff;
  padding: 8px;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease-in-out;
}
.plansButtonContainer:hover {
  scale: 1.02;
}
.planInfo {
  padding: 20px;
}
.plansButtonContainer a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
}
.plansFeatures {
  list-style: circle;
}
.plansFeatures li {
  padding-top: 4px;
}
.plansWrapperContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.planTypes {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.price {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: min-content;
  width: 100%;
}
.price span {
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
}
.planeIcon {
  padding-bottom: 6px;
  font-size: 22px !important;
}
.validity {
  text-align: center;
  padding-bottom: 10px;
  font-weight: 500;
  color: #a1a1a1;
}
.plansBodyContainerHeading {
  padding-left: 2.5rem;
}
.plansBodyContainerHeading span {
  font-size: 16px;
  font-weight: 700;
}
.plansBodyContainer ul {
  padding-right: 2rem;
  list-style-type: none;
}
.checkmark::before {
  content: '✅';
  margin-right: 0.5em;
}
.cross::before {
  content: '❌';
  margin-right: 0.5em;
}
.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin-bottom: 1rem;
}
.heading h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: -0.025em;
}
.heading h3 {
  font-size: 1.25rem;
  line-height: 2.8rem;
  color: rgba(27, 4, 4, 0.5);
  font-weight: 400;
}

