.titleBarLayout {
  background-color: #03204c;
  width: 100%;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  height: 65px;
}

.logoText {
  font-weight: bold;
  font-size: 24px;
  color: #fff;
  margin-top: 0px;
  margin-left: 10px;
}
.logoTextColor {
  font-weight: 800;
  font-size: 24px;
  color: #3f8dce;
  margin-top: 0px;
  margin-left: 10px;
}
.logoWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.profileWrapper {
  justify-content: space-between;
  display: flex;
  flex: 50%;
}
.profileLogo {
  margin-right: 10px;
}
.profileTextWrapper {
  margin-right: 0px;
}
.profileTextMain {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  margin-right: 25px;
}
.profileTextSub {
  font-size: 11px;
  color: #fff;
}
.shopListPopup {
  color: #72777a;
  font-size: 16px;
  background-color: white;
  position: absolute;
  right: 24px;
  top: 75px;
  z-index: 100;
}
.shopListItem {
  padding: 15px 30px;
}
.actionButtons {
  justify-content: center;
  align-self: center;
  margin-right: 10px;
}
.actionBtnIcon {
  font-size: 14px !important;
}
.actionBtn {
  font-size: 11px !important;
  font-weight: 500 !important;
}
.username {
  font-size: 10px;
  margin-left: 8px;
  color: white;
}
.Shop {
  font-size: 12px;
  margin-left: 8px;
  color: grey;
}
.profileSec {
  padding: 15px;
  cursor: pointer;
}
.popUp {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;
}

/*menu btn*/

.menuBtn {
  position: relative;
  height: 23px;
  width: 28px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.menuBtnBurger {
  position: absolute;
  right: 0;
  top: 0.5rem;
  width: 28px;
  height: 3px;
  background: #3f8dce;
  transition: all 0.5s ease-in-out;
}
.menuBtnBurgerOpen {
  transform: rotate(360deg);
  background: transparent;
}

.menuBtnBurger::before {
  content: '';
  position: absolute;
  top: -8px;
  width: 28px;
  height: 3px;
  background: #3f8dce;
  transition: all 0.5s ease-in-out;
}
.menuBtnBurgerOpen::before {
  transform: rotate(45deg) translate(5px, 8px);
}

.menuBtnBurger::after {
  content: '';
  position: absolute;
  top: 8px;
  width: 20px;
  height: 3px;
  background: #3f8dce;
  transition: all 0.5s ease-in-out;
}
.menuBtnBurgerOpen::after {
  width: 28px;
  transform: rotate(-45deg) translate(3px, -7px);
}

@media only screen and (min-width: 600px) {
  .menuBtn {
   display: none;
  }
  
}